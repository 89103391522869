.DocViewer {
  &--title {
    text-transform: capitalize;
  }
  &--section-layout {
    margin: 24px 0;
  }
  &--section-body {
    padding: 24px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    h1 {
      font-size: 24px;
      font-weight: bold;
      margin: 12px 0;
    }

    h2 {
      font-size: 16px;
      font-weight: bold;
      margin: 12px 0;
    }
    p {
      font-size: 14px;
      line-height: 24px;
    }
  }
  &--section-number {
    color: rgba(0, 0, 0, 0.3);
  }
  &--section-cid {
    text-align: right;

    color: rgba(0, 0, 0, 0.3);
    a {
      color: rgba(0, 0, 0, 0.3);
    }
  }

  &--section-amended {
    color: rgba(0, 0, 0, 0.3);
    a {
      color: rgba(0, 0, 0, 0.3);
    }
  }

  &--section-layout div:nth-child(3) {
    text-align: right;
  }
  &--section-layout div:nth-child(4) {
    text-align: right;
  }
}
