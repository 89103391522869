.Body {
  margin: 24px 0;
  &--title div {
    margin: 24px 0;
    font-weight: 200;
    font-size: 33px;
    line-height: 40px;
    letter-spacing: -0.02em;
    color: #414054;
  }
}
