.FixedWidthCentered {
  display: grid;
  height: 100%;

  &--center {
    grid-column: 2;
    height: fit-content;
    width: 100%;
    padding: 0 24px;
  }
}
