.DocumentList {
  margin: 24px 0;
  ul {
    list-style-type: none;
    padding: 0;
    a {
      text-decoration: none;
      li {
        color: rgba(65, 64, 84, 0.5);
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        margin: 24px 0;
      }
      li:hover {
        color: #414054;
      }
    }
  }
}
